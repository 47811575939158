<template>
  <div class="tutorial-screen">
    <component :is="currentStep" />
    <div
      class="
        tutorial-screen-footer
        d-flex
        justify-content-between
        align-items-center
      "
    >
      <div class="left">
        <span @click="backStep" class="pagination disabled">
          <ph-caret-left :size="16" />
        </span>
      </div>
      <div class="center">
        <ul class="dot">
          <li>
            <a class="active" href="#"></a>
          </li>
        </ul>
      </div>
      <div class="right">
        <span @click="nextStep" class="pagination">
          <ph-caret-right :size="16" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import step1 from "./ExportScreen/step1.vue";

export default {
  components: { step1 },
  data() {
    return {
      selectedStep: "step1",
      steps: [
        {
          name: "step1"
        }
      ]
    };
  },
  computed: {
    currentStep() {
      return this.selectedStep;
    }
  },
  methods: {
    nextStep() {
      const lastStep = this.steps[this.steps.length - 1];
      const isLastStep = lastStep.name === this.selectedStep;
      if (isLastStep) {
        return this.$emit("tabChange");
      }
    },
    backStep() {
      const firstStep = this.steps[0];
      const isFirstStep = firstStep.name === this.selectedStep;
      if (isFirstStep) {
        return this.$emit("tabChange", true);
      }
      return false;
    }
  }
};
</script>
<style lang="scss" scoped>
.tutorial-screen {
  margin-top: 75px;

  &-footer {
    margin-top: 50px;
    .pagination {
      cursor: pointer;
      display: flex;
      width: 40px;
      height: 40px;

      background: #ee7f00;
      border-color: #ee7f00;
      color: #ffffff;

      border-radius: 20px;
      align-items: center;
      justify-content: center;
      &.disabled {
        color: #555555;
        background: #ffffff;
        border: 1px solid #cecece;
      }
    }
  }
}
.dot {
  display: flex;
  margin-bottom: 0;
  padding-bottom: 0;
  li {
    list-style-type: none;
    a {
      display: block;
      width: 6px;
      height: 6px;
      background-color: #e5e5e5;
      border-radius: 100%;
      &.active {
        width: 10px;
        height: 10px;
        background-color: #ee7f00;
      }
    }
  }
}
</style>