<template>
  <div class="tutorial-screen-inner align-items-center">
    <div class="tutorial-screen-left">
      <img src="/imgs/export-tutorial-step1.png" alt="Export Tutorial" />
    </div>
    <div class="tutorial-screen-right">
      <h3 class="sub-title">{{ info.subTitle }}</h3>
      <h3 class="screen-title">{{ info.title }}</h3>
      <p class="desc mt">
        Artikel, bei denen entweder nur die fehlende EAN oder VAN eindeutig
        zuzuordnen war, stehen zum Export unter
        <strong>“Mapping erfolgreich”</strong> bereit. Es erfolgt der
        automtische Download einer CSV Datei.Artikel, die kein eindeutiges
        Mapping hatten, können jeweils unter
        <strong>“Bearbeiten”</strong> bearbeitet und dort exportiert werden.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: {
        subTitle: "EXPORT",
        title: "Exportieren von Ergebnissen",
        items: [
          {
            descs: [
              "Trennzeichen: Komma, Semikolon, Tabulator",
              "Header: Spaltentitel",
              "Pflichtspalten: EAN, VAN, Interne Mapping ID des Gesellschafters",
              "! Je mehr Felder Sie angeben, desto genauer wird das vorgeschlagene Ergebnis für die manuelle Zuordnung später."
            ]
          }
        ]
      }
    };
  }
};
</script>

<style lang="scss">
.tutorial-screen {
  &-inner {
    padding-right: 40px;
    padding-left: 65px;
    display: flex;
  }
  &-left {
    min-width: 420px;
    img {
      width: 100%;
    }
  }
  &-right {
    margin-left: 65px;
    .screen-title {
      font-size: 30px;
      line-height: 36px;
      text-transform: uppercase;
      margin-bottom: 32px;
      color: #555555;
    }
    .sub-title {
      font-size: 18px;
      line-height: 22px;
      color: #ee7f00;
      margin-bottom: 10px;
    }
    .desc {
      font-family: Barlow;
      font-size: 16px;
      line-height: 24px;
      color: #555555;

      strong {
        font-family: Barlow;
        font-weight: bold;
      }
      &.mt {
        margin-top: 25px;
      }
    }
  }
}
</style>